export const SLOT_PRODUCT_MESSAGE_FRAGMENT = `
  fragment slotProductMessageFragment on Product {
    uid
    slots: _slots(filter: { code: "message_feature" }) {
      uid
      code
      quantity
      strategy
      type
    }
  }
`;
